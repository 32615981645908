import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Button, Typography, Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faHeart, faComment } from '@fortawesome/free-solid-svg-icons';

const VideoCard = ({ video }) => {
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(true);

  const handleImageError = () => {
    setIsPreviewAvailable(false);
  };

  return (
    <Card 
      sx={{ 
        width: '100%', 
        maxWidth: 345, 
        margin: '0 auto', 
        mb: 2, 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {isPreviewAvailable && video.preview ? (
        <CardMedia
          component="img"
          height="200"
          image={video.preview}
          alt="Video preview"
          onError={handleImageError}
          sx={{ objectFit: 'cover' }}
        />
      ) : (
        <Box 
          sx={{ 
            height: 200, 
            backgroundColor: '#000', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center' 
          }}
        >
          <Typography variant="h6" color="white">Video Unavailable</Typography>
        </Box>
      )}

      <CardContent>
        <Typography gutterBottom variant="h5" component="div" noWrap>
          {video.username || "Unknown User"}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          Sponsor: {video.name || "No Sponsor"}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          Uploaded on: {new Date(video.created).toLocaleDateString()}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faEye} style={{ marginRight: '5px' }} />
            {video.views_total || 0}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faHeart} style={{ marginRight: '5px' }} />
            {video.likes_total || 0}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faComment} style={{ marginRight: '5px' }} />
            {video.comments_total || 0}
          </Box>
        </Box>

        <Button
          href={`/videos/${video.video_id}`}
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
        >
          Details
        </Button>

        <Button
          href={video.url}
          variant="outlined"
          target="_blank"
          fullWidth
          sx={{ mt: 1 }}
        >
          View on Platform
        </Button>
      </CardContent>
    </Card>
  );
};

export default VideoCard;



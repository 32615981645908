import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  Alert,
  Stack,
  IconButton,
  MenuItem,
  Select,
  Button,
  
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Edit, PendingActions, ManageAccounts } from "@mui/icons-material";

const Writers = () => {
  const [view, setView] = useState("pending");
  const [pendingUsers, setPendingUsers] = useState([]);
  const [writers, setWriters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
 
  const [pageSize, setPageSize] = useState(10);
  const [editingRow, setEditingRow] = useState(null);
  const [selectedPaymentScale, setSelectedPaymentScale] = useState({});

  useEffect(() => {
    if (view === "pending") {
      fetchPendingUsers();
    } else {
      fetchWriters();
    }
  }, [view]);

  
  const dataGridStyles = {
    "--DataGrid-overlayHeight": "300px",
    overflow: "clip",
    borderColor: "#E5E7EB",
    backgroundColor: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "0.9rem",
  
    // Column Headers
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F4F6", // Light gray background for a modern look
      color: "#111827", // Dark text for better contrast
      fontSize: "1rem", // Slightly larger font size for clarity
      fontWeight: "600", // Semi-bold for emphasis
      textTransform: "none", // Keep headers clean (no capitalization)
      borderBottom: "2px solid #E5E7EB", // Subtle bottom border
      textAlign: "left", // Align text to the left for readability
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "700", // Stronger emphasis on header titles
        fontSize: "0.95rem", // Slightly refined size for clarity
      },
      "&:hover": {
        backgroundColor: "#E5E7EB", // Subtle hover effect on headers
      },
    },
  
    // Footer Container
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "#F9FAFB",
      borderTop: "1px solid #E5E7EB",
      fontSize: "0.85rem",
      fontWeight: "500",
    },
  
    // Checkbox Customizations
    "& .MuiCheckbox-root": {
      padding: "4px",
      "& > svg": {
        fontSize: "1rem",
      },
    },
  
    // Pagination Controls
    "& .MuiTablePagination-root": {
      marginRight: "8px",
      "& .MuiIconButton-root": {
        maxHeight: "32px",
        maxWidth: "32px",
        "& > svg": {
          fontSize: "1rem",
        },
      },
    },
  
    // Cell Styling
    "& .MuiDataGrid-cell": {
      borderTop: "1px solid #E5E7EB",
      color: "#374151", // Neutral dark gray for cells
      fontSize: "0.9rem",
      "&:hover": {
        backgroundColor: "#F3F4F6", // Subtle hover effect for cells
      },
    },
  
    // Row Styling
    "& .MuiDataGrid-row": {
      "&:last-of-type": {
        borderBottom: "1px solid #E5E7EB",
      },
      "&:hover": {
        backgroundColor: "#F9FAFB", // Light hover effect for rows
      },
      "&.Mui-selected": {
        backgroundColor: "#E0E7FF", // Light blue for selected rows
        "&:hover": {
          backgroundColor: "#C7D2FE", // Slightly darker blue on hover
        },
      },
    },
  
    // Status Indicators
    "& .status-online": {
      color: "#10B981", // Vibrant green for online status
      fontWeight: "bold",
      backgroundColor: "#DCFCE7",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
    "& .status-offline": {
      color: "#9CA3AF", // Subtle gray for offline status
      fontWeight: "bold",
      backgroundColor: "#F3F4F6",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
  };
  

  // Fetch Pending Writers
  const fetchPendingUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/admin/pendingWriters");
      const usersData = response.data;
      const paymentScaleState = {};
      usersData.forEach((user) => {
        paymentScaleState[user.id] = user.payment_scale || "";
      });
      setSelectedPaymentScale(paymentScaleState);
      setPendingUsers(usersData);
    } catch (error) {
      setError("Error fetching pending users.");
    }
    setLoading(false);
  };

  // Fetch Managed Writers
  const fetchWriters = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/admin/writers");
      setWriters(response.data);
    } catch (error) {
      setError("Error fetching writers.");
    }
    setLoading(false);
  };

  // Approve Pending Writer
  const handleApprove = async (id) => {
    const paymentScale = selectedPaymentScale[id];
    console.log("Approving user with ID:", id, "Payment Scale:", paymentScale);
    if (!paymentScale) {
      alert("Please select a valid payment scale before approving.");
      return;
    }
    try {
      await axios.post("/api/admin/approveUser", {
        id,
        payment_scale: paymentScale,
      });
      setSuccess("User approved successfully.");
      fetchPendingUsers();
    } catch (error) {
      console.error("Error approving user:", error.response?.data || error);
      setError("Error approving user.");
    }
  };
  
  

  // Reject Pending Writer
  const handleReject = async (id) => {
    try {
      await axios.post("/api/admin/rejectUser", { id });
      fetchPendingUsers();
    } catch (error) {
      setError("Error rejecting user.");
    }
  };

  // Edit Payment Scale
  const handleEditClick = (id) => {
    setEditingRow(id);
  };

  const handlePaymentScaleChange = async (id, value) => {
    try {
      await axios.put(`/api/admin/writers/${id}`, { payment_scale: value });
      setSuccess("Payment scale updated successfully.");
      setEditingRow(null);
      fetchWriters();
    } catch (error) {
      setError("Error updating payment scale.");
    }
  };

  // Delete Writer
  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/admin/writers/${id}`);
      setSuccess("Writer deleted successfully.");
      fetchWriters();
    } catch (error) {
      setError("Error deleting writer.");
    }
  };

  const pendingColumns = [
    { field: "id", headerName: "User ID", width: 100 },
    { field: "username", headerName: "Username", width: 180 },
    { field: "name", headerName: "Name", width: 180 },
    { field: "role", headerName: "Role", width: 180 },
    {
      field: "payment_scale",
      headerName: "Payment Scale",
      width: 250,
      renderCell: (params) => {
        const { id } = params.row;
        const currentValue = selectedPaymentScale[id] || "";
  
        return params.row.role === "writer" ? (
          <Select
            value={currentValue}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedPaymentScale((prev) => ({
                ...prev,
                [id]: value,
              }));
            }}
            displayEmpty
          >
            <MenuItem value="">Select</MenuItem>
            <MenuItem value="Intern">Intern</MenuItem>
            <MenuItem value="Full Time Employee">Full Time Employee</MenuItem>
          </Select>
        ) : (
          "Not Applicable"
        );
      },
    },
    {
      field: "status",
      headerName: "Actions",
      width: 190,
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleApprove(params.row.id)}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleReject(params.row.id)}
          >
            Reject
          </Button>
        </Stack>
      ),
    },
  ];
  

  const manageColumns = [
    { field: "id", headerName: "#", width: 50 },
    { field: "username", headerName: "Username", width: 250 },
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "payment_scale",
      headerName: "Payment Scale",
      width: 250,
      renderCell: (params) => {
        const isEditing = editingRow === params.row.id;
        return isEditing ? (
          <Select
            value={params.value || ""}
            onChange={(e) => handlePaymentScaleChange(params.row.id, e.target.value)}
            size="small"
          >
            <MenuItem value="Intern">Intern</MenuItem>
            <MenuItem value="Full Time Employee">Full Time Employee</MenuItem>
          </Select>
        ) : (
          <Stack direction="row" alignItems="center">
            {params.value || "N/A"}
            <IconButton onClick={() => handleEditClick(params.row.id)}>
              <Edit />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <Button variant="contained" color="error" onClick={() => handleDelete(params.row.id)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: "bold", textAlign: "left", color: "#333" }}>
        Employee Management
      </Typography>

      <Stack
  direction="row"
  spacing={0} // Remove default spacing as items are now separated by the divider
  sx={{
    mb: 3,
    border: "1px solid #ddd", // Outer border
    borderRadius: "8px", // Rounded corners
    overflow: "hidden", // Clip content
    alignItems: "center",
    width: "fit-content", // Adjust width to content
    height: "48px", // Consistent height
  }}
>
  {/* Pending View Button */}
  <IconButton
    color={view === "pending" ? "primary" : "default"}
    onClick={() => setView("pending")}
    sx={{
      flex: 1,
      borderRadius: 0, // No border radius for inner buttons
    }}
  >
    <PendingActions />
  </IconButton>

  {/* Divider */}
  <Box
    sx={{
      width: "1px", // Thin divider
      backgroundColor: "#ddd", // Divider color
      height: "80%", // Adjust divider height
    }}
  />

  {/* Manage View Button */}
  <IconButton
    color={view === "manage" ? "primary" : "default"}
    onClick={() => setView("manage")}
    sx={{
      flex: 1,
      borderRadius: 0, // No border radius for inner buttons
    }}
  >
    <ManageAccounts />
  </IconButton>
</Stack>


      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}

      <Box sx={{ height: 600, width: "100%" }}>
        {view === "pending" ? (
          <DataGrid
            rows={pendingUsers}
            columns={pendingColumns}
            loading={loading}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            slots={{ toolbar: GridToolbar }}
            sx={dataGridStyles}
          />
        ) : (
          <DataGrid
            rows={writers}
            columns={manageColumns}
            loading={loading}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            slots={{ toolbar: GridToolbar }}
            sx={dataGridStyles}
          />
        )}
      </Box>
    </Box>
  );
};

export default Writers;

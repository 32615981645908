import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Modal,
  TextField,
  Menu,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import ReactECharts from "echarts-for-react";
import dayjs from "dayjs";
import { groupBy } from "lodash"; // Install lodash for grouping
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { DataGrid, GridToolbar,gridClasses } from "@mui/x-data-grid";
import ScoreCardsSponsor from "./ScoreCardsSponsor";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
   
    
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      
      
    },
  },
}));

// Utility function to format numbers
const formatNumber = (value) => {
  return Number.isInteger(value) ? value : value.toFixed(2);
};

// TopBar Component
const TopBar = ({ handleMenuOpen }) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#ffffff",
        boxShadow: "none",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flexGrow: 1,
            color: "#ef6c00",
            fontWeight: "bold",
            marginLeft: "20px",
          }}
        >
          Plotpointe Sponsor Dashboard
        </Typography>

        <IconButton color="inherit" onClick={handleMenuOpen}>
          <MenuIcon sx={{ color: "#ef6c00" }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const SponsorDashboard = () => {
  const [chartData, setChartData] = useState([]);
  const [sponsorName, setSponsorName] = useState("");
  const [sponsorId, setSponsorId] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, "days"));
  const [endDate, setEndDate] = useState(dayjs());
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchSponsorData = async () => {
    try {
      const username = localStorage.getItem("username");
      if (!username) {
        console.error("No username found in local storage.");
        return;
      }

      const loginResponse = await axios.get(`/api/login`, {
        params: { username },
      });
      const loginId = loginResponse.data.login_id;

      if (!loginId) {
        console.error("No login ID found for the user.");
        return;
      }

      const sponsorResponse = await axios.get(`/api/sponsor`, {
        params: { login_id: loginId },
      });
      const sponsor = sponsorResponse.data;

      if (sponsor && sponsor.sponsor_id) {
        setSponsorId(sponsor.sponsor_id);
        setSponsorName(sponsor.name);
        fetchData(sponsor.sponsor_id);
      } else {
        console.error("No sponsor details found for the login ID.");
      }
    } catch (error) {
      console.error("Error fetching sponsor data:", error);
    }
  };

  const fetchData = async (sponsorId) => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/bigquery/views`, {
        params: {
          sponsorId,
          startDate: startDate.format("YYYY-MM-DD HH:mm:ss"),
          endDate: endDate.format("YYYY-MM-DD HH:mm:ss"),
        },
      });
      setChartData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSponsorData();
  }, []);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleApplyDateRange = () => {
    if (sponsorId) {
      fetchData(sponsorId);
    }
    handleModalClose();
  };

  const getCardData = (days) => {
    const recentData = chartData.filter(
      (item) => dayjs().diff(dayjs(item.time), "day") <= days
    );
    const sum = recentData.reduce((sum, item) => sum + item.views, 0);
    return formatNumber(sum) || "No data";
  };

  // Aggregate chartData by day
  const aggregatedChartData = Object.entries(
    groupBy(chartData, (item) => dayjs(item.time).format("YYYY-MM-DD"))
  ).map(([date, items]) => ({
    time: date,
    views: items.reduce((sum, item) => sum + item.views, 0), // Sum of views for the day
  }));

  const chartOptions = {
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(244, 81, 30, 0.2)", // Match the graph's line color with a light shade
      borderColor: "#f4511e", // Same as line color
      borderWidth: 1,
      textStyle: { color: "#333" },
      formatter: (params) => {
        const date = params[0]?.axisValue || "N/A";
        const value = params[0]?.value || 0;
        return `
        <div style="min-width: 150px;">
          <div style="font-size: 12px; color: #747575;">${date}</div>
          <div style="font-size: 18px; font-weight: 600; color: #333;">${value.toLocaleString()} Views</div>
        </div>
      `;
      },
      extraCssText: "box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);",
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: aggregatedChartData.map((item) => dayjs(item.time).format("MMM D")),
      axisLabel: {
        formatter: (value, index) => (index % 2 === 0 ? value : ""), // Skip every other label
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => {
          if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
          if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
          return value;
        },
      },
    },
    series: [
      {
        data: aggregatedChartData.map((item) => item.views),
        type: "line",
        smooth: true,
        lineStyle: { color: "#f4511e" },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: "rgba(244, 81, 30, 0.4)" },
              { offset: 1, color: "rgba(244, 81, 30, 0)" },
            ],
          },
        },
      },
    ],
  };

  
  const dataGridStyles = {
    "--DataGrid-overlayHeight": "300px",
    overflow: "clip",
    borderColor: "#E5E7EB",
    backgroundColor: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "0.9rem",
  
    // Column Headers
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F4F6", // Light gray background for a modern look
      color: "rgb(11,14,20)", // Dark text for better contrast
      fontSize: "1rem", // Slightly larger font size for clarity
      fontWeight: "550", // Semi-bold for emphasis
      textTransform: "none", // Keep headers clean (no capitalization)
      borderBottom: "2px solid #E5E7EB", // Subtle bottom border
      textAlign: "left", // Align text to the left for readability
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "rgb(11,14,20)",
        fontWeight: "550", // Stronger emphasis on header titles
        fontSize: "0.90rem", // Slightly refined size for clarity
      },
      "&:hover": {
        backgroundColor: "#E5E7EB", // Subtle hover effect on headers
      },
    },
  
    // Footer Container
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "#F9FAFB",
      borderTop: "1px solid #E5E7EB",
      fontSize: "0.85rem",
      fontWeight: "500",
    },
  
    // Checkbox Customizations
    "& .MuiCheckbox-root": {
      padding: "4px",
      "& > svg": {
        fontSize: "1rem",
      },
    },
  
    // Pagination Controls
    "& .MuiTablePagination-root": {
      marginRight: "8px",
      "& .MuiIconButton-root": {
        maxHeight: "32px",
        maxWidth: "32px",
        "& > svg": {
          fontSize: "1rem",
        },
      },
    },
  
    // Cell Styling
    "& .MuiDataGrid-cell": {
      borderTop: "1px solid #E5E7EB",
      color: "#374151", // Neutral dark gray for cells
      fontSize: "0.9rem",
      "&:hover": {
        backgroundColor: "#F3F4F6", // Subtle hover effect for cells
      },
    },
  
    // Row Styling
    "& .MuiDataGrid-row": {
      "&:last-of-type": {
        borderBottom: "1px solid #E5E7EB",
      },
      "&:hover": {
        backgroundColor: "#F9FAFB", // Light hover effect for rows
      },
      "&.Mui-selected": {
        backgroundColor: "#E0E7FF", // Light blue for selected rows
        "&:hover": {
          backgroundColor: "#C7D2FE", // Slightly darker blue on hover
        },
      },
    },
  
    // Status Indicators
    "& .status-online": {
      color: "#10B981", // Vibrant green for online status
      fontWeight: "bold",
      backgroundColor: "#DCFCE7",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
    "& .status-offline": {
      color: "#9CA3AF", // Subtle gray for offline status
      fontWeight: "bold",
      backgroundColor: "#F3F4F6",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
  };

  return (
    <Box>
      {/* TopBar */}
      <TopBar handleMenuOpen={handleMenuOpen} />

      {/* Menu for Logout */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ marginRight: 1, color: "#ef6c00" }} />
          Logout
        </MenuItem>
      </Menu>

      {/* Date Range Button */}
      <Box sx={{ position: "absolute", top: 90, right: 40 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#ef6c00",
            color: "white",
            padding: "8px 16px",
            "&:hover": {
              backgroundColor: "#d9534f",
            },
          }}
          onClick={handleModalOpen}
        >
          Select Date Range
        </Button>
      </Box>

      {/* Date Picker Modal */}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" mb={2}>
            Select Date Range
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField {...params} fullWidth sx={{ mb: 2 }} />
              )}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              sx={{
                backgroundColor: "#007bff",
                color: "white",
                "&:hover": { backgroundColor: "#0056b3" },
              }}
              onClick={handleApplyDateRange}
            >
              Apply
            </Button>
            <Button
              sx={{
                backgroundColor: "#28a745",
                color: "white",
                "&:hover": { backgroundColor: "#218838" },
              }}
              onClick={() => {
                setStartDate(dayjs().subtract(30, "days"));
                setEndDate(dayjs());
                setModalOpen(false);
              }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      </Modal>
      <Grid columns={12}>
      <Box sx={{ padding: 4, marginTop: 8 }}>
        {/* Sponsor Name */}
        <Typography
          variant="h5"
          sx={{
            mb: 3,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {sponsorName}'s Dashboard
        </Typography>

        {/* Loading Indicator */}
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="400px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* Scorecards Section */}
            <Box sx={{ marginBottom: 6 }}>
              <Grid
                container
                spacing={3} // Increased spacing between the cards
                justifyContent="center"
                sx={{
                  paddingX: 2,
                  marginTop: "1.5rem", // Bring scorecards down by 1 inch
                }}
              >
                <ScoreCardsSponsor
                  sponsorId={sponsorId}
                  startDate={startDate}
                  endDate={endDate}
                  sx={{ alignItems: "center" }}
                />
              </Grid>
            </Box>

            {/* Chart Section */}
            <Box
              sx={{
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                borderRadius: "12px",
                backgroundColor: "#fff",
                padding: 3,
                marginTop: 4,
                width: "100%",
                maxWidth: "1080px", // Same as scorecards
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <ReactECharts
                option={chartOptions}
                style={{
                  height: "300px", // Reduced height for balance
                  width: "100%",
                }}
              />
            </Box>

            {/* Data Grid Section */}
            <Box
              sx={{
                height: 600,
                marginTop: 6,
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
                borderRadius: "12px",
                backgroundColor: "#fff",
                border: "1px solid #e0e0e0",
                padding: 2,
                width: "calc(100% - 20px)", // Increased width to align with chart
                maxWidth: "1080px", // Match the width of the chart
                marginLeft: "auto",
                marginRight: "auto",
                '& .super-app-theme--header': {
                  backgroundColor: 'rgb(245,246,250)',
                  width:'200px',
                  fontFamily:'inherit',
                  fontWeight: "bold",
                  
                },
              }}
               
            >

    
              <StripedDataGrid
              rowHeight={35}
              columnHeaderHeight={40}
           
              
          rows={chartData.map((item, index) => ({
            id: index,
            ...item,
          }))}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          columns={[ 
            { field: "time", headerName: "Time", flex: 1 ,headerClassName: 'super-app-theme--header'},
            {
              field: "views",
              headerName: "Views",
              flex: 1,
              headerClassName: 'super-app-theme--header',
             
              renderCell: (params) => formatNumber(params.value),
            },
          ]}

  pageSize={20}
  rowsPerPageOptions={[20, 50, 100]}
  disableColumnMenu
  disableSelectionOnClick
  slots={{ toolbar: GridToolbar }}
  sx={dataGridStyles}
/>

     
 
            </Box>
          </>
        )}
      </Box>
      </Grid>
    </Box>
  );
};

export default SponsorDashboard;

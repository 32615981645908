import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Pagination,
  PaginationItem,
  InputAdornment,
  
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TableRowsIcon from "@mui/icons-material/TableRows";
import GridViewIcon from "@mui/icons-material/GridView";
import FilterListIcon from "@mui/icons-material/FilterList";
import VideoCard from "./VideoCard"; // Placeholder for grid card rendering
import axios from "axios";
import { format } from "date-fns";


const SponsoredVideos = () => {
  const [view, setView] = useState("table");
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(8); // For grid view pagination
  const [searchText, setSearchText] = useState("");

  const [filters, setFilters] = useState({
    sponsor: "ALL",
    account: "ALL",
    writer: "ALL",
    minViews: "",
    maxViews: "",
    postedBefore: "",
    postedAfter: "",
    sortBy: "timestamp_desc",
  });
  const [platform, setPlatform] = useState("all");
  const [accounts, setAccounts] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [writers, setWriters] = useState([]);

  useEffect(() => {
    fetchData();
    fetchFilterOptions();
  }, [filters, platform, searchText]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/videos/sponsored", {
        params: { ...filters, platform},
      });

    const data = response.data;

     // Apply filtering based on the search text
     const filteredData = data.filter((video) => {
      const searchLower = searchText.toLowerCase();
      return (
        (video.url && video.url.toLowerCase().includes(searchLower)) ||
        (video.title && video.title.toLowerCase().includes(searchLower))
      );
    });

    setVideoData(filteredData);
    } catch (error) {
      console.error("Error fetching non-sponsored videos", error);
    }
    setLoading(false);
  };


  const dataGridStyles = {
    "--DataGrid-overlayHeight": "300px",
    overflow: "clip",
    borderColor: "#E5E7EB",
    backgroundColor: "#FFFFFF",
    fontFamily: "'Inter', sans-serif",
    fontSize: "0.9rem",
  
    // Column Headers
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#F3F4F6", // Light gray background for a modern look
      color: "#111827", // Dark text for better contrast
      fontSize: "1rem", // Slightly larger font size for clarity
      fontWeight: "600", // Semi-bold for emphasis
      textTransform: "none", // Keep headers clean (no capitalization)
      borderBottom: "2px solid #E5E7EB", // Subtle bottom border
      textAlign: "left", // Align text to the left for readability
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "700", // Stronger emphasis on header titles
        fontSize: "0.95rem", // Slightly refined size for clarity
      },
      "&:hover": {
        backgroundColor: "#E5E7EB", // Subtle hover effect on headers
      },
    },
  
    // Footer Container
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: "#F9FAFB",
      borderTop: "1px solid #E5E7EB",
      fontSize: "0.85rem",
      fontWeight: "500",
    },
  
    // Checkbox Customizations
    "& .MuiCheckbox-root": {
      padding: "4px",
      "& > svg": {
        fontSize: "1rem",
      },
    },
  
    // Pagination Controls
    "& .MuiTablePagination-root": {
      marginRight: "8px",
      "& .MuiIconButton-root": {
        maxHeight: "32px",
        maxWidth: "32px",
        "& > svg": {
          fontSize: "1rem",
        },
      },
    },
  
    // Cell Styling
    "& .MuiDataGrid-cell": {
      borderTop: "1px solid #E5E7EB",
      color: "#374151", // Neutral dark gray for cells
      fontSize: "0.9rem",
      "&:hover": {
        backgroundColor: "#F3F4F6", // Subtle hover effect for cells
      },
    },
  
    // Row Styling
    "& .MuiDataGrid-row": {
      "&:last-of-type": {
        borderBottom: "1px solid #E5E7EB",
      },
      "&:hover": {
        backgroundColor: "#F9FAFB", // Light hover effect for rows
      },
      "&.Mui-selected": {
        backgroundColor: "#E0E7FF", // Light blue for selected rows
        "&:hover": {
          backgroundColor: "#C7D2FE", // Slightly darker blue on hover
        },
      },
    },
  
    // Status Indicators
    "& .status-online": {
      color: "#10B981", // Vibrant green for online status
      fontWeight: "bold",
      backgroundColor: "#DCFCE7",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
    "& .status-offline": {
      color: "#9CA3AF", // Subtle gray for offline status
      fontWeight: "bold",
      backgroundColor: "#F3F4F6",
      borderRadius: "12px",
      padding: "2px 8px",
      fontSize: "0.85rem",
      textAlign: "center",
    },
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  
  
  
  const fetchFilterOptions = async () => {
    try {
      const sponsorsResponse = await axios.get("/api/videos/sponsors");
      const accountsResponse = await axios.get("/api/videos/accounts");
      const writersResponse = await axios.get("/api/videos/writers");
      setSponsors(sponsorsResponse.data);
      setAccounts(accountsResponse.data);
      setWriters(writersResponse.data);
    } catch (error) {
      console.error("Error fetching filter options", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchFilterOptions();
  }, [filters, platform]);

  const handleViewChange = (event, nextView) => {
    setView(nextView);
  };

    // Sorting logic
    const sortData = (data) => {
        switch (filters.sortBy) {
          case "timestamp_asc":
            return data.sort((a, b) => new Date(a.created) - new Date(b.created));
          case "timestamp_desc":
            return data.sort((a, b) => new Date(b.created) - new Date(a.created));
          case "views_asc":
            return data.sort((a, b) => a.views_total - b.views_total);
          case "views_desc":
            return data.sort((a, b) => b.views_total - a.views_total);
          case "likes_asc":
            return data.sort((a, b) => a.likes_total - b.likes_total);
          case "likes_desc":
            return data.sort((a, b) => b.likes_total - a.likes_total);
          case "comments_asc":
            return data.sort((a, b) => a.comments_total - b.comments_total);
          case "comments_desc":
            return data.sort((a, b) => b.comments_total - a.comments_total);
          default:
            return data;
        }
      };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleNewSearch = () => fetchData();

  const handleResetFilters = () => {
    setFilters({
      sponsor: "ALL",
      account: "ALL",
      writer: "ALL",
      minViews: "",
      maxViews: "",
      postedBefore: "",
      postedAfter: "",
      sortBy: "timestamp_desc",
    });
    setPlatform("all");
    setSearchText("");
  };

  const columns = [
    { field: "video_id", headerName: "Video ID", width: 100 },
    { field: "url", headerName: "URL", width: 200 },
    {
      field: "details",
      headerName: "Details",
      width: 150,
      renderCell: (params) => (
        <a href={`/videos/${params.row.video_id}`}>Details</a>
      ),
    },
    { field: "name", headerName: "Sponsor", width: 150 },
    { field: "username", headerName: "Account", width: 150 },
    { field: "writername", headerName: "Writer", width: 150 },
    { field: "views_total", headerName: "Views", width: 100 },
    { field: "likes_total", headerName: "Likes", width: 100 },
    { field: "comments_total", headerName: "Comments", width: 100 },
    {
      field: "created",
      headerName: "Timestamp",
      width: 150,
      renderCell: (params) =>
        format(new Date(params.value), "dd/MM/yyyy, HH:mm"),
    },
  ];

  const sortedData = sortData([...videoData]); // Apply sorting logic here

const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
const currentRecords = sortedData.slice(indexOfFirstRecord, indexOfLastRecord);


  return (
    <Box sx={{ p: 3, width: 1175 }}>
    {/* Title and Search Bar */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Sponsored Videos
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
     
      <TextField
        variant="outlined"
        size="small"
        label="Search by URL"
        value={searchText}
        onChange={handleSearchChange}
        sx={{ width: 300 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="search">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
    </Box>

      {/* Filters and View Toggle */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="view toggle"
        >
          <ToggleButton value="table" aria-label="table view">
            <TableRowsIcon />
          </ToggleButton>
          <ToggleButton value="grid" aria-label="grid view">
            <GridViewIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        

        <IconButton
          onClick={() => setShowFilters(!showFilters)}
          sx={{
            border: "1px solid #ff6d00",
            borderRadius: 1,
            backgroundColor: showFilters ? "#ff6d00" : "transparent",
            color: showFilters ? "#fff" : "#ff6d00",
          }}
        >
          <FilterListIcon />
        </IconButton>
      </Box>

      {/* Filters Panel */}
      {showFilters && (
        <Box
          sx={{
            mb: 3,
            p: 2,
            border: "1px solid #ffcc80",
            borderRadius: 2,
            backgroundColor: "#fff3e0",
           
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Filters
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              alignItems: "center",
           
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              sx={{ width: 110, mr: 1 }}
            >
              <InputLabel>Platform</InputLabel>
              <Select
                name="platform"
                value={platform}
                onChange={(e) => setPlatform(e.target.value)}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="tiktok">TikTok</MenuItem>
                <MenuItem value="youtube">YouTube</MenuItem>
                <MenuItem value="instagram">Instagram</MenuItem>
                <MenuItem value="facebook">Facebook</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" size="small" sx={{ width: 100, mr: 1}}>
              <InputLabel>Sponsor</InputLabel>
              <Select
                name="sponsor"
                value={filters.sponsor}
                onChange={handleFilterChange}
              >
                <MenuItem value="ALL">ALL</MenuItem>
                {sponsors.map((sponsor) => (
                  <MenuItem key={sponsor.id} value={sponsor.name}>
                    {sponsor.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              size="small"
              sx={{width: 110, mr: 1 }}
            >
              <InputLabel>Account</InputLabel>
              <Select
                name="account"
                value={filters.account}
                onChange={handleFilterChange}
              >
                <MenuItem value="ALL">ALL</MenuItem>
                {accounts.map((account) => (
                  <MenuItem key={account.id} value={account.username}>
                    {account.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              size="small"
              sx={{ width: 110, mr: 1 }}
            >
              <InputLabel>Writer</InputLabel>
              <Select
                name="writer"
                value={filters.writer}
                onChange={handleFilterChange}
              >
                <MenuItem value="ALL">ALL</MenuItem>
                {writers.map((writer) => (
                  <MenuItem key={writer.id} value={writer.name}>
                    {writer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Min Views"
              name="minViews"
              value={filters.minViews}
              onChange={handleFilterChange}
              variant="outlined"
              size="small"
              type="number"
              sx={{ width: 110  , mr: 1 }}
            />
            <TextField
              label="Max Views"
              name="maxViews"
              value={filters.maxViews}
              onChange={handleFilterChange}
              variant="outlined"
              size="small"
              type="number"
              sx={{ width: 110, mr: 1 }}
            />
            <TextField
              label="Posted After"
              name="postedAfter"
              value={filters.postedAfter}
              onChange={handleFilterChange}
              variant="outlined"
              size="small"
              type="date"
              sx={{ width: 130, mr: 1 }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Posted Before"
              name="postedBefore"
              value={filters.postedBefore}
              onChange={handleFilterChange}
              variant="outlined"
              size="small"
              type="date"
              sx={{ width: 130, mr: 1 }}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Sort By
            </Typography>
            <RadioGroup
              row
              name="sortBy"
              value={filters.sortBy}
              onChange={handleFilterChange}
            >
              <FormControlLabel
                value="timestamp_desc"
                control={<Radio size="small" />}
                label="Timestamp Desc"
              />
              <FormControlLabel
                value="timestamp_asc"
                control={<Radio size="small" />}
                label="Timestamp Asc"
              />
              <FormControlLabel
                value="views_desc"
                control={<Radio size="small" />}
                label="Views Desc"
              />
              <FormControlLabel
                value="views_asc"
                control={<Radio size="small" />}
                label="Views Asc"
              />
              <FormControlLabel
                  value="likes_desc"
                  control={<Radio size="small" />}
                  label="Likes Desc"
                />
                <FormControlLabel
                  value="likes_asc"
                  control={<Radio size="small" />}
                  label="Likes Asc"
                />
                <FormControlLabel
                  value="comments_desc"
                  control={<Radio size="small" />}
                  label="Comments Desc"
                />
                <FormControlLabel
                  value="comments_asc"
                  control={<Radio size="small" />}
                  label="Comments Asc"
                />
            </RadioGroup>
          </Box>

          <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleNewSearch}
              sx={{
                backgroundColor: "#ff6d00",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#e65b00", // Slightly darker orange for hover
                },
              }}
            >
              New Search
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleResetFilters}
              sx={{
                color: "#ff6d00",
                borderColor: "#ff6d00",
                "&:hover": {
                  backgroundColor: "#ffe6cc", // Light orange background on hover
                  borderColor: "#ff6d00",
                },
              }}
            >
              Reset
            </Button>
          </Box>
        </Box>
      )}

      {/* Data Grid or Grid View */}
      {view === "table" ? (
        <Box
          sx={{
            height: 600,
            "& .MuiDataGrid-root": {
              borderRadius: 2,
              fontSize: "0.9rem",
            },
          }}
        >
          <DataGrid
            rows={videoData}
            columns={columns}
            pageSize={8}
            loading={loading}
            getRowId={(row) => row.video_id}
            slots={{ toolbar: GridToolbar }}
            sx={dataGridStyles}
          />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {currentRecords.map((video) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={video.video_id}>
              <VideoCard video={video} />
            </Grid>
          ))}
        </Grid>
      )}

      {/* Pagination for Grid View */}
      {view === "grid" && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Pagination
            count={Math.ceil(videoData.length / recordsPerPage)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default SponsoredVideos;



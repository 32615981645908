// src/components/BarChartLikesComponent.js
import React from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);


const BarChartLikesComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No hourly likes data available</p>;
  }

  // Format data for the chart
  const formattedData = data.map(item => ({
    time: dayjs(item._time).tz('America/New_York').format('MMM D, HH:mm'), // Convert to EST
    value: item._value,
  }));

  const options = {
    xAxis: {
      type: 'category',
      data: formattedData.map(item => item.time),
      axisLine: { lineStyle: { color: '#e0e0e0' } },
      axisLabel: {
        color: '#8c8c8c',
        fontSize: 12,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: { lineStyle: { color: '#e0e0e0' } },
      axisLabel: {
        color: '#8c8c8c',
        fontSize: 12,
        formatter: (value) => {
          if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
          if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
          return value.toLocaleString();
        },
      },
      splitLine: { lineStyle: { color: '#f0f0f0' } },
    },
    series: [
      {
        name: 'Hourly Likes',
        data: formattedData.map(item => item.value),
        type: 'bar',
        barWidth: '60%',
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: '#f4511e' }, // Start with solid orange at the top
              { offset: 1, color: '#ffccbc' }, // Fade to a lighter orange at the bottom
            ],
          },
          borderColor: 'rgba(0, 0, 0, 0.3)', // Light border for contrast
          borderWidth: 1,
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#ffffff',
      borderColor: '#ccc',
      borderWidth: 1,
      textStyle: { color: '#666' },
      formatter: (params) => {
        const date = params[0]?.name || 'N/A';
        const value = params[0]?.value || 0;
        return `
          <div style="min-width: 200px;">
            <div style="font-size: 12px; color: #747575;">${date}</div>
            <div style="font-size: 18px; font-weight: 600; color: #000;">Likes: ${value.toLocaleString()}</div>
          </div>
        `;
      },
      extraCssText: 'box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);',
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '3%',
      top: '10%',
      containLabel: true,
    },
  };

  return <ReactECharts option={options} style={{ height: '400px', width: '750px' }} />;
};

export default BarChartLikesComponent;

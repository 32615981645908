import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Box, Alert, Card, CardContent, Typography, Chip } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import ReactECharts from "echarts-for-react";

const formatLargeNumber = (value) => {
  if (!value || isNaN(value)) return "N/A";
  if (value >= 1e6) return `${(value / 1e6).toFixed(1).replace(/\.0$/, "")}M`;
  if (value >= 1e3) return `${(value / 1e3).toFixed(1).replace(/\.0$/, "")}K`;
  return value.toFixed(1).replace(/\.0$/, "");
};

const ScoreCardsSponsor = ({ sponsorId, startDate, endDate }) => {
  const [data, setData] = useState({
    totalViews: { value: "0", trend: 0, chartData: [] },
    last7Days: { value: "0", trend: 0, chartData: [] },
    last14Days: { value: "0", trend: 0, chartData: [] },
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    if (!sponsorId) {
      setError("No sponsor ID provided.");
      setLoading(false);
      return;
    }
  
    try {
      setLoading(true);
      setError(null);
  
      // Fetch data for the current 30 days
      const totalViewsResponse = await axios.get(`/api/bigquery/views`, {
        params: {
          sponsorId,
          startDate: dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
          endDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
        },
      });
  
      // Fetch data for the last 7 days
      const last7DaysResponse = await axios.get(`/api/bigquery/views`, {
        params: {
          sponsorId,
          startDate: dayjs().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"),
          endDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        },
      });
  
      // Fetch data for the last 14 days
      const last14DaysResponse = await axios.get(`/api/bigquery/views`, {
        params: {
          sponsorId,
          startDate: dayjs().subtract(14, "days").format("YYYY-MM-DD HH:mm:ss"),
          endDate: dayjs().subtract(7, "days").format("YYYY-MM-DD HH:mm:ss"),
        },
      });
  
      // Fetch data for the previous 30 days (before the current 30 days)
      const previous30DaysResponse = await axios.get(`/api/bigquery/views`, {
        params: {
          sponsorId,
          startDate: dayjs(startDate).subtract(30, "days").format("YYYY-MM-DD HH:mm:ss"),
          endDate: dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
        },
      });
  
      // Calculate totals for each period
      const totalViews = totalViewsResponse.data.reduce((sum, item) => sum + item.views, 0);
      const totalChartData = totalViewsResponse.data.map((item) => item.views);
  
      const last7Days = last7DaysResponse.data.reduce((sum, item) => sum + item.views, 0);
      const last7ChartData = last7DaysResponse.data.map((item) => item.views);
  
      const last14Days = last14DaysResponse.data.reduce((sum, item) => sum + item.views, 0);
      const last14ChartData = last14DaysResponse.data.map((item) => item.views);
  
      const previous30Days = previous30DaysResponse.data.reduce((sum, item) => sum + item.views, 0);
  
      // Calculate trends
      const delta30Days = previous30Days > 0 ? ((totalViews - previous30Days) / previous30Days) * 100 : 0;
      const delta7Days = last14Days > 0 ? ((last7Days - last14Days) / last14Days) * 100 : 0;
      const delta14Days = totalViews > 0 ? ((last14Days - totalViews) / totalViews) * 100 : 0;
  
      setData({
        totalViews: { value: formatLargeNumber(totalViews), trend: delta30Days, chartData: totalChartData },
        last7Days: { value: formatLargeNumber(last7Days), trend: delta7Days, chartData: last7ChartData },
        last14Days: { value: formatLargeNumber(last14Days), trend: delta14Days, chartData: last14ChartData },
      });
    } catch (err) {
      console.error("Error fetching scorecard data:", err);
      setError("Failed to load scorecard data.");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [sponsorId, startDate, endDate]);

  const renderCard = (title, subtitle, value, trend, chartData) => {
    const chipColor = trend > 0 ? "#4CAF50" : trend < 0 ? "#F44336" : "#9E9E9E";

    const sparklineOptions = {
      grid: {
        top: 10,
        bottom: 0,
        left: 0,
        right: 0,
      },
      xAxis: {
        type: "category",
        show: false,
        data: chartData.map((_, index) => index),
      },
      yAxis: {
        type: "value",
        show: false,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "line" },
        backgroundColor: "#ffffff",
        borderColor: "#cccccc",
        borderWidth: 1,
        textStyle: { color: "#333333", fontSize: 10 },
        formatter: (params) => {
          if (!params || params.length === 0) return "";
          const [point] = params;
          return `<div><strong>Value:</strong> ${Math.round(point.data)}</div>`;
        },
      },
      series: [
        {
          data: chartData,
          type: "line",
          smooth: false,
          symbol: "none",
          areaStyle: { color: "rgba(244, 81, 30, 0.1)" },
          lineStyle: { color: "#F4511E", width: 2 },
        },
      ],
    };

    return (
      <Card
        sx={{
          borderRadius: 4,
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          overflow: "hidden",
          width: "300px",
          height: "210px",
         
        }}
      >
        <CardContent>
          <Typography variant="subtitle2" sx={{ color: "#424242" }}>
            {title}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 1 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold", color: "#212121", mr: 2 }}>
              {value}
            </Typography>
            <Chip
              label={`${trend > 0 ? "+" : ""}${trend.toFixed(1)}%`}
              size="small"
              sx={{
                backgroundColor: "#ffffff",
                color: chipColor,
                fontWeight: "bold",
                border: `1px solid ${chipColor}`,
              }}
            />
          </Box>
          <Typography
            variant="caption"
            sx={{ display: "block", color: "#757575", mb: 1 }}
          >
            {subtitle}
          </Typography>
          <ReactECharts option={sparklineOptions} style={{ height: "60px", width: "180px" }} />
        </CardContent>
      </Card>
    );
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: "100%", maxWidth: "1100px", margin: "0 auto" }}>
    <Grid container spacing={8}>
      <Grid item  sx={{marginLeft:"30px"}}>
        {renderCard("Total Views", "Last 30 days", data.totalViews.value, data.totalViews.trend, data.totalViews.chartData)}
      </Grid>
      <Grid item sx={{marginLeft:"20px"}}>
        {renderCard("Views", "Last 7 days", data.last7Days.value, data.last7Days.trend, data.last7Days.chartData)}
      </Grid>
      <Grid item sx={{marginLeft:"20px"}}>
        {renderCard("Views", "Last 14 days", data.last14Days.value, data.last14Days.trend, data.last14Days.chartData)}
      </Grid>
    </Grid>
  </Box>
  
  );
};

export default ScoreCardsSponsor;



import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography, Chip, Box } from "@mui/material";
import ReactECharts from "echarts-for-react";

const StatCard = ({ title, value, trend, data }) => {
  // Trend label and color based on the percentage
  const trendColors = {
    up: "#4CAF50", // Green for upward trend
    down: "#F44336", // Red for downward trend
    neutral: "#9E9E9E", // Gray for neutral trend
  };

  const chipColor = trend > 0 ? trendColors.up : trend < 0 ? trendColors.down : trendColors.neutral;

  // If trend is NaN or null, show "0%"
  const trendLabel = isNaN(trend) || trend === null ? "0%" : `${trend > 0 ? "+" : ""}${trend.toFixed(1)}%`;

  // Orange hues for graph colors
  const orangeHues = {
    light: "rgba(255, 120, 60, 1)", // Light orange
    medium: "rgba(255, 100, 40, 1)", // Medium orange
    dark: "rgba(255, 80, 20, 1)", // Dark orange
    gradient: "rgba(255, 80, 20, 0.3)", // Gradient light orange
  };

  const sparklineOptions = {
    grid: {
      top: 10,
      bottom: 0,
      left: 0,
      right: 0,
    },
    xAxis: {
      type: "category",
      show: false,
      data: data.map((_, index) => index),
    },
    yAxis: {
      type: "value",
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line", // Line pointer for better precision
      },
      backgroundColor: "#ffffff", // White background
      borderColor: "#cccccc", // Light gray border
      borderWidth: 1,
      textStyle: {
        color: "#333333", // Dark text
        fontSize: 10, // Smaller font size for a more compact tooltip
      },
      extraCssText: "box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); padding: 5px; border-radius: 5px;", // Add shadow and padding for better visibility and reduced size
      position: (point, params, dom, rect, size) => {
        const [x, y] = point; // Coordinates of the mouse
        const { contentSize } = size;
  
        // Ensure tooltip appears outside the card
        let tooltipX = x + 10; // Offset tooltip slightly to the right
        let tooltipY = y - 10; // Offset tooltip slightly above
  
        // Prevent tooltip from overflowing on the right
        if (tooltipX + contentSize[0] > window.innerWidth) {
          tooltipX = window.innerWidth - contentSize[0] - 60;
        }
  
        // Prevent tooltip from overflowing below the viewport
        if (tooltipY + contentSize[1] > window.innerHeight) {
          tooltipY = window.innerHeight - contentSize[1] + 60;
        }
  
        return [tooltipX, tooltipY];
      },
      formatter: (params) => {
        if (!params || params.length === 0) return "";
        const [point] = params; // Get the hovered point
        return `
          <div style="text-align: left; font-size: 15px; line-height:1.5;">
            <strong>Value:</strong> ${Math.round(point.data)}<br/>
          
          </div>
        `;
      },
    },
    series: [
      {
        data: data,
        type: "line",
        smooth: false, // ECG-style line
        symbol: "none",
        areaStyle: { color: `${orangeHues.gradient}` }, // Slight transparency for the area fill
        lineStyle: { color: orangeHues.dark, width: 2 },
      },
    ],
  };
  

  return (
    <Card
      sx={{
        borderRadius: 4,
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        overflow: "hidden",
        position: "relative",
        width: "260px", // Adjust card width for smaller layout
        minWidth: "240px",
        height:"195px" // Minimum width for better design
      }}
    >
      <CardContent>
        <Typography variant="subtitle2" sx={{ color:"#424242" }}>
          {title}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 1 }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", color: "#212121", mr: 2 }}
          >
            {value.toLocaleString()}
          </Typography>
          <Chip
            label={trendLabel}
            size="small"
            sx={{
              backgroundColor: "#ffffff", // White background for better contrast
              color: chipColor, // Green or Red text
              fontWeight: "bold",
              border: `1px solid ${chipColor}`, // Border matches trend color
              ml: "auto",
            }}
          />
        </Box>
        <Typography
          variant="caption"
          sx={{ display: "block", color: "#757575", mt: 1 }}
        >
          Last 30 days
        </Typography>
        <ReactECharts
          option={sparklineOptions}
          style={{ height: "30px", width: "90%" }} // Reduced graph size
        />
      </CardContent>
    </Card>
  );
};

StatCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  trend: PropTypes.number.isRequired, // Expecting a percentage value
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default StatCard;




import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function HighlightedCard() {
    const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        borderRadius: 4,
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        overflow: "hidden",
        position: "relative",
        width: "270px", // Adjust card width for smaller layout
        minWidth: "240px",
        height: "195px",
        backgroundColor: "#F8FAFC"
      }}
    >
      <CardContent>
        <InsightsRoundedIcon />
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: "600" }}
        >
          Explore your data
        </Typography>
        <Typography sx={{ color: "text.secondary", mb: "2px" }}>
          Uncover performance and visitor insights with our data wizardry.
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="primary"
          endIcon={<ChevronRightRoundedIcon />}
          fullWidth={isSmallScreen}
          onClick={() => navigate("/sponsored")}
        >
          Get insights
        </Button>
      </CardContent>
    </Card>
  );
}

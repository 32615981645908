import React from "react";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";

import Home from "./components/Home";
import Register from "./components/Register";
import WriterRegister from "./components/WriterRegister";
import SponsorRegister from "./components/SponsorRegister";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Welcome from "./components/Welcome";
import Dashboard from "./components/Dashboard";
import WriterDashboard from "./components/WriterDashboard";
import SponsorDashboard from "./components/SponsorDashboard";
import VideoDetailsSponsor from "./components/VideoDetailsSponsor";
import AdminDashboard from "./components/AdminDashboard";
import AdminAnalytics from "./components/AdminAnalytics";
import VideoDetails from "./components/VideoDetails";
import VideoDetailsTabs from "./components/VideoDetailsTab"; // For the tabs in Video Details
import Sponsored from "./components/Sponsored";
import NonSponsored from "./components/NonSponsored";
import TikTok from "./components/Tiktok";
import Facebook from "./components/Facebook";
import YouTube from "./components/Youtube";
import Instagram from "./components/Instagram";
import Repost from "./components/Repost";
import Layout from "./components/Layout"; // Layout wrapper
import WriterLayout from './components/WriterLayout'; // Updated to use WriterLayout
import VideoEntry from "./components/VideoEntry";
import PendingWriters from "./components/PendingWriters";
import RepostEntries from "./components/RepostEntries"; // Import RepostEntries
import ManageWriters from "./components/ManageWriters";
import SettingsPage from "./components/SettingsPage";
import WriterAnalytics from "./components/WriterAnalytics";
import Unauthorized from "./components/Unauthorized";
import WriterPayouts from "./components/WriterPayouts"


import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {
  return (
    <AuthProvider>
     
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/register-writer" element={<WriterRegister />} />
          <Route path="/register-sponsor" element={<SponsorRegister />} />
          <Route
              path="dashboard"
              element={
                <ProtectedRoute
                  component={Dashboard}
                  allowedRoles={["admin"]}
                />
              }
            />


          {/* Unauthorized page */}
          <Route path="/unauthorized" element={<Unauthorized />} />
         
          <Route element={<WriterLayout />}>
          <Route
            path="writeranalytics"
            element={
              <ProtectedRoute
                component={WriterAnalytics}
                allowedRoles={["writer"]}
              />
            }
          />
           <Route
              path="writerdashboard"
              element={
                <ProtectedRoute
                  component={WriterDashboard}
                  allowedRoles={["writer"]}
                />
              }

            />
            <Route
              path="writerpayouts"
              element={
                <ProtectedRoute
                  component={WriterPayouts}
                  allowedRoles={["writer"]}
                />
              }

            />
            </Route>
             <Route
              path="sponsordashboard"
              element={
                <ProtectedRoute
                  component={SponsorDashboard}
                  allowedRoles={["sponsor", "admin"]}
                />
              }
            />
           

          {/* Layout wrapper for protected routes */}
          <Route path="/*" element={<Layout />}>
         
            {/* Admin and protected routes */}
            <Route
            path="home"
            element={
              <ProtectedRoute
                component={Home}
                allowedRoles={["admin"]}
              />
            }
          />

            <Route
            path="sponsored"
            element={
              <ProtectedRoute
                component={Sponsored}
                allowedRoles={["admin"]}
              />
            }
          />
            <Route
            path="non-sponsored"
            element={
              <ProtectedRoute
                component={NonSponsored}
                allowedRoles={["admin"]}
              />
            }
          />
           <Route
            path="video-entry"
            element={
              <ProtectedRoute
                component={VideoEntry}
                allowedRoles={["admin"]}
              />
            }
          />
            <Route
            path="admindashboard"
            element={
              <ProtectedRoute
                component={AdminDashboard}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="analytics"
            element={
              <ProtectedRoute
                component={AdminAnalytics}
                allowedRoles={["admin"]}
              />
            }
          />
           
           
           
            <Route
              path="repost"
              element={
                <ProtectedRoute
                  component={RepostEntries}
                  allowedRoles={["admin"]}
                />
              }
            />
            <Route
              path="logout"
              element={
                <ProtectedRoute
                  component={Logout}
                  allowedRoles={["admin", "sponsor", "writer"]}
                />
              }
            />
            <Route
              path="video-details/:videoUrl"
              element={
                <ProtectedRoute
                  component={VideoDetailsSponsor}
                  allowedRoles={["sponsor", "admin"]}
                />
              }
            />

            {/* Nested routes for Video Details */}
            <Route path="video-details/*" element={<VideoDetailsTabs />}>
              <Route
                path="tiktok"
                element={
                  <ProtectedRoute component={TikTok} allowedRoles={["admin"]} />
                }
              />
              <Route
                path="facebook"
                element={
                  <ProtectedRoute
                    component={Facebook}
                    allowedRoles={["admin"]}
                  />
                }
              />
              <Route
                path="youtube"
                element={
                  <ProtectedRoute
                    component={YouTube}
                    allowedRoles={["admin"]}
                  />
                }
              />
              <Route
                path="instagram"
                element={
                  <ProtectedRoute
                    component={Instagram}
                    allowedRoles={["admin"]}
                  />
                }
              />
              <Route
                path="repost"
                element={
                  <ProtectedRoute component={Repost} allowedRoles={["admin"]} />
                }
              />
            </Route>
            

           

            {/* Other protected routes */}

            <Route
              path="videos/:videoId"
              element={
                <ProtectedRoute
                  component={VideoDetails}
                  allowedRoles={["admin"]}
                />
              }
            />
            <Route
              path="pendingwriters"
              element={
                <ProtectedRoute
                  component={PendingWriters}
                  allowedRoles={["admin"]}
                />
              }
            />
            <Route
              path="managewriters"
              element={
                <ProtectedRoute
                  component={ManageWriters}
                  allowedRoles={["admin"]}
                />
              }
            />

            <Route
              path="settings"
              element={
                <ProtectedRoute
                  component={SettingsPage}
                  allowedRoles={["admin"]}
                />
              }
            />
          </Route>
        </Routes>
      
    </AuthProvider>
  );
};

export default App;

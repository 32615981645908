import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  TextField,
  Grid,
  Divider,
  IconButton,
  MenuItem,

} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AdminSidebar from "./AdminSidebar";

const Settings = () => {
  const [apiKey, setApiKey] = useState("");
  const [token, setToken] = useState("");
  const [listId, setListId] = useState("");
  const [editing, setEditing] = useState(false);
  const [currentSection, setCurrentSection] = useState("Settings");

 
  const [editingAccount, setEditingAccount] = useState(null);
  

  const [accountList, setAccountList] = useState([]);
  const [newAccount, setNewAccount] = useState({
    account: "",
    platform: "",
    status: "",
  });
  const [editingRow, setEditingRow] = useState(null);

  const platforms = ["TikTok", "YouTube", "Instagram", "Facebook"];
  const statuses = ["Active", "Inactive"];

  // Fetch settings and account list on component mount
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get("/api/settings");
        const { api_key, token, list_id } = response.data;
        setApiKey(api_key);
        setToken(token);
        setListId(list_id);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };


    const fetchAccounts = async () => {
      try {
        const response = await axios.get("/api/accounts");
        setAccountList(response.data.accounts);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    fetchSettings();
    fetchAccounts();
  }, []);

  // Handle save of new settings
  const handleSave = async (newApiKey, newToken, newListId) => {
    try {
      await axios.post("/api/settings", {
        apiKey: newApiKey,
        token: newToken,
        listId: newListId,
      });
      setApiKey(newApiKey);
      setToken(newToken);
      setListId(newListId);
      setEditing(false);
    } catch (error) {
      console.error("Error saving settings:", error);
    }
  };

  // Handle adding a new account
  const handleAddAccount = async () => {
    const { account, platform, status } = newAccount;

    if (!account || !platform || !status) return;

    try {
      const response = await axios.post("/api/accounts", newAccount);
      setAccountList((prev) => [...prev, response.data]);
      setNewAccount({ account: "", platform: "", status: "" });
    } catch (error) {
      console.error("Error adding account:", error);
    }
  };

  // Handle editing an account
  const handleEditAccount = async (id) => {
    const updatedAccount = accountList.find((acc) => acc.id === id);
  
    if (!editingAccount || !editingAccount.account.trim()) {
      console.error("Account name cannot be empty.");
      return;
    }
  
    try {
      const response = await axios.put(`/api/accounts/${id}`, editingAccount);
      setAccountList((prev) =>
        prev.map((acc) => (acc.id === id ? response.data : acc))
      );
      setEditingRow(null);
      setEditingAccount(null);
    } catch (error) {
      console.error("Error editing account:", error);
    }
  };
  
  

  // Handle deleting an account
  const handleDeleteAccount = async (id) => {
    try {
      await axios.delete(`/api/accounts/${id}`);
      setAccountList((prev) => prev.filter((acc) => acc.id !== id));
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const columns = [
    {
      field: "account",
      headerName: "Account Name",
      flex: 1,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <TextField
            fullWidth
            value={editingAccount?.account || params.value}
            onChange={(e) =>
              setEditingAccount((prev) => ({ ...prev, account: e.target.value }))
            }
          />
        ) : (
          <Typography>{params.value}</Typography>
        ),
    },
    {
      field: "platform",
      headerName: "Platform",
      flex: 1,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <TextField
            select
            fullWidth
            value={editingAccount?.platform || params.value}
            onChange={(e) =>
              setEditingAccount((prev) => ({ ...prev, platform: e.target.value }))
            }
          >
            {platforms.map((platform) => (
              <MenuItem key={platform} value={platform}>
                {platform}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <Typography>{params.value}</Typography>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <TextField
            select
            fullWidth
            value={editingAccount?.status || params.value}
            onChange={(e) =>
              setEditingAccount((prev) => ({ ...prev, status: e.target.value }))
            }
          >
            {statuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <Typography>{params.value}</Typography>
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      align: "center",
      flex: 0.5,
      renderCell: (params) =>
        editingRow === params.row.id ? (
          <>
            <IconButton
              color="primary"
              onClick={() => handleEditAccount(params.row.id)}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => {
                setEditingRow(null);
                setEditingAccount(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton
              color="primary"
              onClick={() => {
                setEditingRow(params.row.id);
                setEditingAccount(params.row); // Store current row data for editing
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => handleDeleteAccount(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
    },
  ];
  
  return (
    <Box sx={{ display: "flex", p: 2 }}>
      {/* Sidebar */}
      <AdminSidebar setCurrentSection={setCurrentSection} />

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, px: 4 }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", mb: 2, color: "#333" }}
        >
          {currentSection}
        </Typography>

        <Stack spacing={2} width={'1050px'}>
          
        <Card variant="outlined" sx={{ p: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Manage Posting Accounts
            </Typography>
            <Divider sx={{ my: 2 }} />

            {/* Add New Account */}
            <Box
  sx={{
    display: "flex",
    alignItems: "center", // Center elements vertically
    gap: 2, // Space between items
    mb: 4, // Margin at the bottom
  }}
>
  <TextField
    fullWidth
    label="Account Name"
    value={newAccount.account || ""}
    sx={{ flex: 2, maxWidth: "400px" }} // Control width and flex ratio
    onChange={(e) =>
      setNewAccount((prev) => ({ ...prev, account: e.target.value }))
    }
  />
  <TextField
    select
    label="Platform"
    value={newAccount.platform || ""}
    sx={{ flex: 1, minWidth: "120px" }} // Adjust width
    onChange={(e) =>
      setNewAccount((prev) => ({ ...prev, platform: e.target.value }))
    }
  >
    {platforms.map((platform) => (
      <MenuItem key={platform} value={platform}>
        {platform}
      </MenuItem>
    ))}
  </TextField>
  <TextField
    select
    label="Status"
    value={newAccount.status || ""}
    sx={{ flex: 1, minWidth: "120px" }} // Adjust width
    onChange={(e) =>
      setNewAccount((prev) => ({ ...prev, status: e.target.value }))
    }
  >
    {statuses.map((status) => (
      <MenuItem key={status} value={status}>
        {status}
      </MenuItem>
    ))}
  </TextField>
  <Button
    variant="contained"
    onClick={handleAddAccount}
    sx={{
      flex: 0,
      backgroundColor: "#1976d2",
      color: "#fff",
      height: "56px", // Match the height of TextFields
    }}
  >
    Add
  </Button>
</Box>


            {/* Account List DataGrid */}
            <Box sx={{ height: 500 }}>
              <DataGrid
               minHeight={45}
                rows={accountList}
                columns={columns}
                disableSelectionOnClick
                disableColumnMenu
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f5f5f5",
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "1px solid #e0e0e0",
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: "1px solid #e0e0e0",
                  },
                }}
              />
            </Box>
          </CardContent>
        </Card>
          {/* Trello Connection Settings */}
          <Card variant="outlined" sx={{ p: 2 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Trello Connection Settings
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="API Key"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    disabled={!editing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Token"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    disabled={!editing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="List ID"
                    value={listId}
                    onChange={(e) => setListId(e.target.value)}
                    disabled={!editing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2}>
                    {!editing ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setEditing(true)}
                      >
                        Edit
                      </Button>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            handleSave(apiKey, token, listId)
                          }
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => setEditing(false)}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

        </Stack>
      </Box>
    </Box>
  );
};

export default Settings;

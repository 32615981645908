import React from "react";
import ReactECharts from "echarts-for-react";
import { Card, CardContent, Typography, Box, Chip } from "@mui/material";

const BarChartHome = ({ sponsorsMetrics }) => {
  // Calculate total views for the last 30 days
  const totalViewsLast30Days = sponsorsMetrics.reduce(
    (sum, item) => sum + item.total_views,
    0
  );

  // Calculate total views for the previous 30 days
  const totalViewsPrevious30Days = sponsorsMetrics.reduce(
    (sum, item) => sum + (item.previous_total_views || 0),
    0
  );

  // Calculate percentage change
  const percentageChange =
    totalViewsPrevious30Days > 0
      ? ((totalViewsLast30Days - totalViewsPrevious30Days) /
          totalViewsPrevious30Days) *
        100
      : totalViewsLast30Days > 0
      ? 100 // If there were no views previously but there are views now
      : 0;

  const trendLabel =
    percentageChange > 0
      ? `+${percentageChange.toFixed(1)}%`
      : `${percentageChange.toFixed(1)}%`;
  const trendColor = percentageChange > 0 ? "success" : "error";

  const options = {
    title: {
       
       
        left: "center",
        textStyle: { fontSize: 16, fontWeight: "bold" },
        subtextStyle: { fontSize: 12, color: "#555" },
      },
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
    },
   
    grid: {
      left: "10%",
      right: "10%",
      bottom: "25%",
      
    },
    xAxis: {
      type: "category",
      data: sponsorsMetrics.map((item) => item.sponsor_name),
      axisLabel: {
        
        rotate: 45, // Rotate for better spacing
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) =>
          value >= 1e6
            ? `${(value / 1e6).toFixed(1)}M`
            : value.toLocaleString(),
      },
      splitLine: { lineStyle: { color: "#f0f0f0" } },
    },
    series: [
      {
        name: "Views",
        type: "bar",
        stack: "metrics",
        data: sponsorsMetrics.map((item) => item.total_views),
        itemStyle: { borderRadius: [4, 4, 0, 0], color: "#FF6D00" },
      },
      {
        name: "Likes",
        type: "bar",
        stack: "metrics",
        data: sponsorsMetrics.map((item) => item.total_likes),
        itemStyle: { borderRadius: [4, 4, 0, 0], color: "#FFA500" },
      },
      {
        name: "Comments",
        type: "bar",
        stack: "metrics",
        data: sponsorsMetrics.map((item) => item.total_comments),
        itemStyle: { borderRadius: [4, 4, 0, 0], color: "#FFD700" },
      },
    ],
  };

  return (
    <Card
      sx={{
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Sponsor Metrics
          </Typography>
       


        </Box>
        <Typography variant="body2" sx={{ mb: 2 }}>
        Metrics per day for the last 30 days
        </Typography>
        
        
        <ReactECharts option={options} style={{ height: "405px", width: "595px" }} />
      </CardContent>
    </Card>
  );
};

export default BarChartHome;

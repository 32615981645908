import React, { useState, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Avatar,
  Typography,
  Divider,
  IconButton,
  CircularProgress,
  Alert,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import LogoutIcon from "@mui/icons-material/Logout";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import axios from "axios";

const drawerWidthOpen = 210;
const drawerWidthClosed = 64;

const WriterSidebar = ({ isDrawerOpen, writerData }) => {
  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path);

  return (
    <Drawer
      sx={{
        width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isDrawerOpen ? drawerWidthOpen : drawerWidthClosed,
          boxSizing: "border-box",
          transition: "width 0.3s",
          top: 0,
          position: "fixed",
          height: "100%",
          overflowX: "hidden",
          backgroundColor: "#F8FAFC",
        },
      }}
      variant="permanent"
      open={isDrawerOpen}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          backgroundColor: "#F8FAFC",
          border: "1px solid #D1D5DB",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
          borderRadius: "6px",
          m: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 30,
            height: 30,
            backgroundColor: "#E8F0FE",
            borderRadius: "50%",
            mr: 2,
            ml: 1,
          }}
        >
          <DevicesRoundedIcon sx={{ fontSize: 20, color: "#1976D2" }} />
        </Box>
        {isDrawerOpen && (
          <Box>
            <Typography
              variant="h6"
              sx={{ fontSize: "0.9rem", fontWeight: "bold" }}
            >
              Plotpointe
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontSize: "0.6rem" }}
            >
              Writer Dashboard
            </Typography>
          </Box>
        )}
        {isDrawerOpen && (
          <IconButton size="small" sx={{ ml: "auto", color: "#6B7280" }}>
            <ArrowDropDownIcon sx={{ fontSize: 16 }} />
          </IconButton>
        )}
      </Box>

      <Divider sx={{ borderColor: "#D1D5DB", marginY: 0.5 }} />

      {/* Navigation Menu */}
      <List sx={{ mt: 0.5 }}>
        {[
          {
            text: "Script Submit",
            icon: <CreateIcon fontSize="small" />,
            link: "/writerdashboard",
          },
          {
            text: "Analytics",
            icon: <AnalyticsIcon fontSize="small" />,
            link: "/writeranalytics",
          },
        ].map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              component={Link}
              to={item.link}
              selected={isActive(item.link)}
              sx={{
                borderRadius: 2,
                px: 0.8,
                marginLeft: "10px",
                marginRight: "10px",
                color: isActive(item.link) ? "#111827" : "#6B7280",
                backgroundColor: isActive(item.link)
                  ? "#E5E7EB"
                  : "transparent",
                "&:hover": {
                  backgroundColor: "#E5E7EB",
                  color: "#111827",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color: isActive(item.link) ? "#111827" : "#6B7280",
                  minWidth: "25px",
                  "& svg": {
                    fontSize: "15px",
                  },
                }}
              >
                {item.icon}
              </ListItemIcon>

              {isDrawerOpen && (
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontSize: "0.875rem",
                    fontWeight: 530,
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider sx={{ borderColor: "#D1D5DB", marginY: 0.5 }} />
      <List>
           {/* Logout */}
           <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/logout"
            sx={{
              borderRadius: 2,
              justifyContent: isDrawerOpen ? "flex-start" : "center",
              color: "#6B7280",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#E5E7EB",
                color: "#111827",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: "#6B7280",
                minWidth: "25px", // Reduced width
                "& svg": {
                  fontSize: "15px", // Smaller icon
                },
              }}
            >
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            {isDrawerOpen && (
              <ListItemText
                primary="Logout"
                primaryTypographyProps={{
                  fontSize: "0.875rem", // Smaller font size
                  fontWeight: 530,
                }}
              />
            )}
          </ListItemButton>
        </ListItem>
      </List>

      {/* Footer Section */}
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          alignItems: "center",
          p: 0.8,
          borderTop: "1px solid #E5E7EB",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Avatar
          alt={writerData?.name || "Writer"}
          src="/static/images/avatar/1.jpg"
          sx={{ width: 24, height: 24, mr: 0.5 }}
        />
        {isDrawerOpen && writerData && (
          <Box>
            <Typography
              variant="body2"
              sx={{ fontSize: "0.875rem", fontWeight: "bold" }}
            >
              {writerData.name || "Writer"}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontSize: "0.7rem" }}
            >
              {writerData.email || "writer@plotpointe.com"}
            </Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

const WriterLayout = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const [writerData, setWriterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const username = localStorage.getItem("username");

  useEffect(() => {
    const fetchWriterData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/api/getWriter?username=${username}`);
        setWriterData(response.data);
      } catch (err) {
        console.error("Error fetching writer data:", err);
        setError("Failed to load writer information.");
      } finally {
        setLoading(false);
      }
    };

    if (username) fetchWriterData();
  }, [username]);

  return (
    <Box sx={{ display: "flex" }}>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <WriterSidebar isDrawerOpen={isDrawerOpen} writerData={writerData} />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          transition: "margin 0.3s",
          marginLeft: `${isDrawerOpen ? drawerWidthOpen : drawerWidthClosed}px`,
          backgroundColor: "#fff",
          position: "absolute",
          scrollBehavior: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default WriterLayout;
import React from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);


const BarChartComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No bar chart data available</p>;
  }

  // Filter data to show only the past 2 days
  const pastTwoDaysData = data.filter(item =>
    dayjs().diff(dayjs(item._time), 'day') <= 2
  );

  // Format data for the chart
  const formattedData = pastTwoDaysData.map(item => ({
    time: dayjs(item._time).tz('America/New_York').format('MMM D, HH:mm'), // Convert to EST
    value: item._value,
  }));

  const options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow', // Tooltip shadow
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: formattedData.map(item => item.time),
      axisTick: { alignWithLabel: true },
      axisLine: { lineStyle: { color: '#e0e0e0' } },
      axisLabel: {
        color: '#8c8c8c',
        fontSize: 12,
      },
    },
    yAxis: {
      type: 'value',
      axisLine: { lineStyle: { color: '#e0e0e0' } },
      axisLabel: {
        color: '#8c8c8c',
        fontSize: 12,
        formatter: (value) => {
          if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
          if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
          return value.toLocaleString();
        },
      },
      splitLine: { lineStyle: { color: '#f0f0f0' } },
    },
    series: [
      {
        name: 'Views',
        type: 'bar',
        barWidth: '60%',
        data: formattedData.map(item => item.value),
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: '#f4511e' }, // Solid color at top
              { offset: 1, color: '#ffccbc' }, // Lighter gradient at bottom
            ],
          },
          borderColor: 'rgba(0, 0, 0, 0.3)', // Light black outline
          borderWidth: 1,
        },
      },
    ],
  };

  return (
    <ReactECharts 
      option={options} 
      style={{ height: '400px', width: '750px'}} 
      notMerge={true}
      lazyUpdate={true}
    />
  );
};

export default BarChartComponent;


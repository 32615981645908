import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  CircularProgress,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { format } from "date-fns";


import StatCard from "./StatCard";
import HighlightedCard from "./HighLightedCard";
import LineChartHome from "./LineChartHome";
import BarChartHome from "./BarChartHome";


const AdminDashboard = () => {
  const [stats, setStats] = useState({
    total_views: 0,
    total_likes: 0,
    total_comments: 0,
    trends: { views: 0, likes: 0, comments: 0 },
  });
  const [sponsorsViews, setSponsorsViews] = useState([]);
  const [sponsorsMetrics, setSponsorsMetrics] = useState([]);
  const [topVideos, setTopVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statsResponse = await axios.get("/api/videos/stats");
        const viewsResponse = await axios.get("/api/top-sponsors-views");
        const metricsResponse = await axios.get("/api/top-sponsors-metrics");
        const topVideosResponse = await axios.get("/api/top-performing-videos");

        setStats(statsResponse.data);
        setSponsorsViews(viewsResponse.data);
        setSponsorsMetrics(metricsResponse.data);
        setTopVideos(topVideosResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Prepare data for charts
  const formattedDates = [
    ...new Set(sponsorsViews.map((item) => item.posted_date)),
  ].sort();
  const sponsorNames = [
    ...new Set(sponsorsViews.map((item) => item.sponsor_name)),
  ];

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        
      >
        <CircularProgress />
      </Box>
    );
  }

  const columns = [
    {
      field: "video_url",
      headerName: "Video URL",
      flex: 3,
      renderCell: (params) => (
        <a href={params.value} target="_blank" rel="noopener noreferrer">
          {params.value}
        </a>
      ),
    },
    { field: "views", headerName: "Views", flex: 1 },
    { field: "likes", headerName: "Likes", flex: 1 },
    { field: "comments", headerName: "Comments", flex: 1 },
    { field: "posted_date", headerName: "Posted Date", flex:2,renderCell: (params) =>
      format(new Date(params.value), "dd/MM/yyyy, HH:mm")}
 
  ];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
        Dashboard Overview
      </Typography>
      <Grid container columns={12}>
        {/* Stat Cards */}
        <Grid container spacing={2} sx={{ marginBottom: "13px" }} columns={12}>
          <Grid item xs={12} sm={6} lg={3}>
            <StatCard
              title="Total Views"
              value={
                stats.total_views >= 1e6
                  ? `${(stats.total_views / 1e6).toFixed(1)}M`
                  : stats.total_views >= 1e3
                    ? `${(stats.total_views / 1e3).toFixed(1)}K`
                    : stats.total_views
              }
              trend={stats.trends.views}
              data={Array.from({ length: 30 }, () => Math.random() * 100)}
              color="#ff6d00"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <StatCard
              title="Total Likes"
              value={
                stats.total_likes >= 1e6
                  ? `${(stats.total_likes / 1e6).toFixed(1)}M`
                  : stats.total_likes >= 1e3
                    ? `${(stats.total_likes / 1e3).toFixed(1)}K`
                    : stats.total_likes
              }
              trend={stats.trends.likes}
              data={Array.from({ length: 30 }, () => Math.random() * 50)}
              color="#ff6d00"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <StatCard
              title="Total Comments"
              value={
                stats.total_comments >= 1e6
                  ? `${(stats.total_comments / 1e6).toFixed(1)}M`
                  : stats.total_comments >= 1e3
                    ? `${(stats.total_comments / 1e3).toFixed(1)}K`
                    : stats.total_comments
              }
              trend={stats.trends.comments}
              data={Array.from({ length: 30 }, () => Math.random() * 20)}
              color="#ff6d00"
            />
          </Grid>

          <Grid item size={{ xs: 12, sm: 6, lg: 3 }}>
            <HighlightedCard />
          </Grid>
        </Grid>

        {/* Line Chart */}
        <Grid item xs={12} md={6}>
          <LineChartHome
            sponsorsViews={sponsorsViews}
            sponsorNames={sponsorNames}
            formattedDates={formattedDates}
          />
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={12} md={6}>
          <BarChartHome sponsorsMetrics={sponsorsMetrics} />
        </Grid>

        {/* Data Grid */}
        <Grid item xs={12}>
          <Card sx={{ mt: 4, p: 2, boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
                Top Performing Videos (Last 30 Days)
              </Typography>
              <Box sx={{ height: 600 }}>
                <DataGrid
                  rows={topVideos}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                  disableVirtualization
                  sx={{
                    "& .MuiDataGrid-cell": { padding: "8px" },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#f5f5f5",
                      fontWeight: "bold",
                    },
                  }}
                />
                
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;

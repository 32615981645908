import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import ReactECharts from "echarts-for-react";

const CumulativeLineChart = ({ sponsorsViews, sponsorNames, formattedDates }) => {
  const sponsorColors = [
    { line: "rgba(66, 135, 245, 1)", area: "rgba(66, 135, 245, 0.5)" }, // Vivid Blue
    { line: "rgba(235, 87, 87, 1)", area: "rgba(235, 87, 87, 0.5)" }, // Bright Red
    { line: "rgba(39, 174, 96, 1)", area: "rgba(39, 174, 96, 0.5)" }, // Vibrant Green
    { line: "rgba(241, 196, 15, 1)", area: "rgba(241, 196, 15, 0.5)" }, // Bright Yellow
    { line: "rgba(52, 152, 219, 1)", area: "rgba(52, 152, 219, 0.5)" }, // Sky Blue
    { line: "rgba(231, 76, 60, 1)", area: "rgba(231, 76, 60, 0.5)" }, // Bright Coral
    { line: "rgba(46, 204, 113, 1)", area: "rgba(46, 204, 113, 0.5)" }, // Lime Green
    { line: "rgba(243, 156, 18, 1)", area: "rgba(243, 156, 18, 0.5)" }, // Orange
    { line: "rgba(44, 62, 80, 1)", area: "rgba(44, 62, 80, 0.5)" }, // Dark Navy Blue
    { line: "rgba(192, 57, 43, 1)", area: "rgba(192, 57, 43, 0.5)" }, // Deep Red
  ];
  
      
      
  const cumulativeLineSeries = sponsorNames.map((sponsor, idx) => {
    let cumulativeSum = 0;

    const { line, area } = sponsorColors[idx % sponsorColors.length];

    return {
      name: sponsor,
      type: "line",
      smooth: true,
      showSymbol: false, // Remove dots
      data: formattedDates.map((date) => {
        const views =
          sponsorsViews.find(
            (item) =>
              item.sponsor_name === sponsor && item.posted_date === date
          )?.total_views || 0;
        cumulativeSum += views;
        return cumulativeSum;
      }),
      lineStyle: { color: line, width: 2 },
      areaStyle: {
        opacity: 0.6,
        color: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            { offset: 0, color: line }, // Match line color for consistency
            { offset: 1, color: area }, // Gradient to transparent
          ],
        },
      },
    };
  });

  const cumulativeLineOptions = {
    title: {
     
      left: "center",
      textStyle: { fontSize: 16, fontWeight: "bold", color: "#333" },
      subtextStyle: { fontSize: 12, color: "#666" },
    },
    tooltip: { trigger: "axis" },
    grid: {
      top: "10%",
      left: "10%",
      right: "10%",
      bottom: "15%",
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: formattedDates.map((date) =>
        new Date(date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
        })
      ),
      axisLabel: { fontSize: 10 },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) =>
          value >= 1e6
            ? `${(value / 1e6).toFixed(1)}M`
            : value.toLocaleString(),
        fontSize: 10,
      },
    },
    series: cumulativeLineSeries,
  };

  return (
    <Card
      sx={{
       
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            width:'550px',

        
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
          Sponsor Views
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, marginTop: "10px", color: "#666" }}>
        Views per day for the last 30 days
        </Typography>
        <ReactECharts
          option={cumulativeLineOptions}
          style={{ height: "410px", width: "100%" }}
        />
      </CardContent>
    </Card>
  );
};

export default CumulativeLineChart;


import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Modal,
  Button,
  Box,
  TextField,
  Divider,
  CircularProgress,
  Alert,

} from "@mui/material";
import { Table, Form, Row, Col } from "react-bootstrap";
import ReactECharts from 'echarts-for-react'; // Import ECharts component
import { useNavigate, useParams } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import LineChartComponent from "./LineChartComponent";
import BarChartComponent from "./BarChartComponent";
import LineChartLikesComponent from "./LineChartLikesComponent";
import BarChartLikesComponent from "./BarChartLikesComponent";
import Scorecards from "./Scorecards";

const formatNumber = (value) => {
  return value !== undefined && !isNaN(value) ? value.toLocaleString() : "N/A";
};

const VideoDetails = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [lineChartData, setLineChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartLikesData, setLineChartLikesData] = useState([]);
  const [barChartLikesData, setBarChartLikesData] = useState([]);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(21, "day"));
  const [endDate, setEndDate] = useState(dayjs());
  const [openDateModal, setOpenDateModal] = useState(false);
  const [commentsStatus, setCommentsStatus] = useState({ active: 0, deleted: 0 });
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    url: "",
    sponsor_id: "",
    account_id: "",
    writer_id: "",
  });

  const [sponsors, setSponsors] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [writers, setWriters] = useState([]);

  useEffect(() => {
    const handlePopState = (event) => {
      navigate("/admindashboard");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const isBase64 = (str) => {
    if (typeof str !== "string" || str.trim() === "") {
      return false;
    }
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  const atobSafe = (str) => {
    if (isBase64(str)) {
      return atob(str);
    } else {
      console.warn("Invalid Base64 string");
      return ""; // Return empty string if invalid
    }
  };

  // Function to fetch comments status data
const fetchCommentsStatus = async (url) => {
  try {
    const response = await axios.get(`/api/videoCommentsStatus?url=${url}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching comments status:', error);
    return { active: 0, deleted: 0 };
  }
};

  // Fetch data for dropdowns
  const fetchDropdownData = async () => {
    try {
      const [sponsorsRes, accountsRes, writersRes] = await Promise.all([
        axios.get("/api/videos/sponsors"),
        axios.get("/api/videos/accounts"),
        axios.get("/api/videos/writers"),
      ]);
      setSponsors(sponsorsRes.data);
      setAccounts(accountsRes.data);
      setWriters(writersRes.data);
    } catch (err) {
      console.error("Error fetching dropdown data:", err);
      setError("Failed to load dropdown data");
    }
  };

  // Open date range modal
  const handleOpenDateModal = () => setOpenDateModal(true);
  const handleCloseDateModal = () => setOpenDateModal(false);

  // Apply selected date range
  const applyDateRange = () => {
    handleCloseDateModal();
    fetchChartData(startDate.toISOString(), endDate.toISOString());
  };

  // Reset date range to default
  const resetDateRange = () => {
    const defaultStart = dayjs().subtract(21, "day");
    const defaultEnd = dayjs();
    setStartDate(defaultStart);
    setEndDate(defaultEnd);
    fetchChartData(defaultStart.toISOString(), defaultEnd.toISOString());
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const videoResponse = await axios.get(`/api/videoDetails/${videoId}`);
        setVideo(videoResponse.data);
        // Decode Base64 script if needed
        const decodedScript = videoResponse.data.script
          ? atobSafe(videoResponse.data.script)
          : "";
        setVideo({ ...videoResponse.data, script: decodedScript });

        setFormData({
          url: videoResponse.data.url || "",
          sponsor_id: videoResponse.data.sponsor_id || "",
          account_id: videoResponse.data.account_id || "",
          writer_id: videoResponse.data.writer_id || "",
        });

        await fetchDropdownData();

        if (videoResponse.data?.url) {
          fetchChartData(videoResponse.data.url);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [videoId, startDate, endDate]);

  useEffect(() => {
    if (video?.url) {
      fetchCommentsStatus(video.url).then(setCommentsStatus);
    }
  }, [video?.url]);

  const fetchChartData = async (url) => {
    try {
      const lineChartStart = dayjs().subtract(21, "day");
      const barChartStart = dayjs().subtract(2, "day");

      const [
        lineDataResponse,
        barDataResponse,
        lineLikesResponse,
        barLikesResponse,
      ] = await Promise.all([
        axios.post("/api/influx/line-chart-data", {
          url,
          startDate: lineChartStart.toISOString(),
          endDate: endDate.toISOString(),
        }),
        axios.post("/api/influx/bar-chart-data", {
          url,
          startDate: barChartStart.toISOString(),
          endDate: endDate.toISOString(),
        }),
        axios.post("/api/influx/line-chart-likes", {
          url,
          startDate: lineChartStart.toISOString(),
          endDate: endDate.toISOString(),
        }),
        axios.post("/api/influx/bar-chart-likes", {
          url,
          startDate: barChartStart.toISOString(),
          endDate: endDate.toISOString(),
        }),
      ]);

      setLineChartData(lineDataResponse.data);
      setBarChartData(barDataResponse.data);
      setLineChartLikesData(lineLikesResponse.data);
      setBarChartLikesData(barLikesResponse.data);
    } catch (err) {
      console.error("Error fetching chart data:", err);
      setError("Failed to load chart data");
    }
  };

  const pieChartOptions = {
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)',
    },
    legend: {
      orient: 'horizontal',
      bottom: '5%',
      top:'90%',
      itemGap: 10,
      data: ['Active', 'Deleted'],
      textStyle: {
        color: '#999',
      },
    },
    color: ['#ff6d00', '#d3d3d3'], // Orange for Active, Gray for Deleted
    series: [
      {
        name: 'Comments Status',
        type: 'pie',
        radius: ['70%', '90%'],
        avoidLabelOverlap: false,
        minAngle: 10, // Ensure small segments are visible with a minimum angle
        label: {
          show: false,
        },
        
        labelLine: {
          show: false,
        },
        data: [
          { value: commentsStatus.active, name: 'Active' },
          { value: commentsStatus.deleted, name: 'Deleted' },
        ],
      },
    ],
  };
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(`/api/updateVideo/${videoId}`, formData);
      if (response.status === 200) {
        setSuccess("Video updated successfully!");
        const updatedVideoResponse = await axios.get(
          `/api/videoDetails/${videoId}`
        );
        setVideo(updatedVideoResponse.data);
      }
    } catch (error) {
      console.error("Error updating video:", error);
      setError("Error updating video");
    }
  };

  if (loading) {
    return (
      <div style={styles.loadingOverlay}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container sx={{ mt: 3 }}>
      {error ? (
        <Alert severity="error" style={{ marginTop: "20px" }}>
          {error}
        </Alert>
      ) : (
        <>
          {/* Date Range Button at the Top Right */}
         
          <Grid container spacing={2}>
            {/* Scorecards for Aggregated Metrics */}
            <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {video?.url && <Scorecards videoId={videoId} />}
              </div>
            </Grid>

           
              {/* Right Column - Video Details Card */}

              {/* Left Column - Line and Bar Charts */}
              <Grid item  xs={12} md={8}>
                <Card style={{ marginBottom: "20px", position: "relative", width:'750px' }}>
                  <CardContent>
                    {/* Title and Date Range Selector */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Overall Views
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={handleOpenDateModal}
                        style={{
                          backgroundColor: "#ff6d00",
                          color: "#fff",
                        }}
                      >
                        Select Date Range
                      </Button>
                    </div>

                    {/* Line Chart */}
                    <LineChartComponent
                      data={lineChartData}
                      onDateRangeChange={fetchChartData}
                    />

                    {/* Date Range Modal */}
                    <Modal open={openDateModal} onClose={handleCloseDateModal}>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: 400,
                          bgcolor: "background.paper",
                          boxShadow: 24,
                          p: 4,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          Select Date Range
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                maxDate={endDate}
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                minDate={startDate}
                                renderInput={(params) => (
                                  <TextField {...params} fullWidth />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                fullWidth
                                onClick={applyDateRange}
                              >
                                Apply
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                fullWidth
                                onClick={resetDateRange}
                              >
                                Reset to Default
                              </Button>
                            </Grid>
                          </Grid>
                        </LocalizationProvider>
                      </Box>
                    </Modal>
                  </CardContent>
                </Card>
                <Card style={{ marginBottom: "20px", width:'750px' }}>
                  <CardContent>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Hourly Views
                    </Typography>
                    <BarChartComponent data={barChartData} />
                  </CardContent>
                </Card>

                <Card style={{ marginBottom: "20px", width:'750px' }}>
                  <CardContent>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Overall Likes
                    </Typography>
                    <LineChartLikesComponent data={lineChartLikesData} />
                  </CardContent>
                </Card>

             

                <Card style={{width:'750px'}}>
                  <CardContent>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Hourly Likes
                    </Typography>
                    <BarChartLikesComponent data={barChartLikesData} />
                  </CardContent>
                </Card>
              </Grid>

              
              <Grid item xs={1} marginRight={'10px'}>
                
                <Card style={{ width: "350px" }}>
                  <CardContent>
                    <Typography vvariant="h4" style={{ fontWeight: "bold" }}>Video Details</Typography>
                    <Divider style={{ margin: "10px 0" }} />
                    <Table>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Video ID:</strong>
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              value={video.video_id}
                              disabled
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>URL:</strong>
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="url"
                              value={formData.url}
                              onChange={handleChange}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Sponsor:</strong>
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              name="sponsor_id"
                              value={formData.sponsor_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Sponsor</option>
                              {sponsors.map((sponsor) => (
                                <option key={sponsor.id} value={sponsor.id}>
                                  {sponsor.name}
                                </option>
                              ))}
                            </Form.Control>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Account:</strong>
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              name="account_id"
                              value={formData.account_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Account</option>
                              {accounts.map((account) => (
                                <option key={account.id} value={account.id}>
                                  {account.username}
                                </option>
                              ))}
                            </Form.Control>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Writer:</strong>
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              name="writer_id"
                              value={formData.writer_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Writer</option>
                              {writers.map((writer) => (
                                <option key={writer.id} value={writer.id}>
                                  {writer.name}
                                </option>
                              ))}
                            </Form.Control>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Views:</strong>
                          </td>
                          <td>{video.views_total}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Likes:</strong>
                          </td>
                          <td>{video.likes_total}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Comments:</strong>
                          </td>
                          <td>{video.comments_total}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Shares:</strong>
                          </td>
                          <td>{video.shares_total}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Saves:</strong>
                          </td>
                          <td>{video.saves_total}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Timestamp:</strong>
                          </td>
                          <td>{new Date(video.created).toLocaleString()}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Script Text</strong>
                          </td>
                          <td>
                            <div
                              style={{
                                whiteSpace: "pre-wrap",
                                padding: "10px",
                                borderRadius: "5px",
                                backgroundColor: "#f0f0f0",
                                fontFamily: "Arial, sans-serif",
                                fontSize: "14px",
                                color: "#333",
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                                maxHeight: "280px",
                                overflowY: "auto",
                              }}
                            >
                              {video.script}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      style={{ backgroundColor: "#ff6d00" }}
                      onClick={handleSave}
                    >
                      Update Video
                    </Button>
                  </CardContent>
                </Card>

                 {/* Comments Status Card */}
          <Card style={{ width: '350px' , marginTop:'10px'}}>
            <CardContent>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Active vs Deleted Comments
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" style={{ marginBottom: '5px' }}>
                Last 28 Days • Estimated
              </Typography>
              <Box display="flex" alignItems="center" style={{height:'200px'}}>
                <Box flex={2} textAlign="center" >
                  <Table>
                    <tr>
                      <td> <Typography style={{ color: '#ff6d00' }}>Active</Typography>
                  <Typography variant="h7" style={{ color: '#ff6d00' }}>
                    {formatNumber(commentsStatus.active)}
                  </Typography></td>
                    </tr>
                    <tr>
                      <td> <Typography  style={{ color: '#ccc' }}>Deleted</Typography>
                  <Typography variant="h7" style={{ color: '#000' }}>
                    {formatNumber(commentsStatus.deleted)}
                  </Typography></td>
                    </tr>
                  </Table>
                 
                </Box>
                
                <Box flex={2} style={{height:'200px'}}>
                  <ReactECharts option={pieChartOptions} style={{ height: '210px',marginBottom:'40px' }} />
                </Box>
              </Box>
            </CardContent>
          </Card>
              </Grid>
            </Grid>
        
      
        </>
      )}
    </Container>
  );
};

// Loading overlay styles
const styles = {
  loadingOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  topRightButton: {
    position: "absolute",
    top: 0,
    right: 0,
    alignItems: "left",
  },
};

export default VideoDetails;
  